<template>
	<CommonPage
		title="Terms of services"
		:back="true">
		<div class="terms">
			<h2>Acceptance of Terms</h2>
			<p>By accessing and using quranjourney.org, you agree to comply with and be bound by these User Terms. If you do not agree with any part of these terms, please do not use our website.</p>

			<h2>Use of Content</h2>
			<p>All content provided on quranjourney.org is for informational and educational purposes only. You may not use the content for any unlawful purpose or in any manner that could harm the website or its users.</p>

			<h2>User Conduct</h2>
			<p>You agree to use quranjourney.org only for lawful purposes. You are prohibited from posting or transmitting any material that could be considered offensive, harmful, or otherwise inappropriate.</p>

			<h2>Privacy</h2>
			<p>Your use of quranjourney.org is also governed by our Privacy Policy, which you can find below.</p>

			<h2>Limitation of Liability</h2>
			<p>quranjourney.org and its team are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the website.</p>

			<h2>Modifications to Terms</h2>
			<p>We reserve the right to modify these User Terms at any time. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of the new terms.</p>
		</div>
	</CommonPage>
</template>

<script>
const title = 'Quran&Journey Org - Terms of Service'
const description = 'Read the terms of service for using Quran&Journey Org. Understand your rights and responsibilities as a user of our platform.'
export default {
	name: 'terms',
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
	mounted () {
		document.querySelector('body').style.backgroundColor = '#fcfbfb'
	}
}
</script>
